import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { PROJECTS } from 'constants/projects';
import { ProjectMainLayout } from 'layouts/ProjectMainLayout';
import { GridThree } from 'components/GridThree';

export const CiechView = () => {
    const images = useStaticQuery(graphql`
        query CiechImages {
            upper: file(name: { eq: "ciech-upper" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
            lower: file(name: { eq: "ciech-lower" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
            main: file(name: { eq: "ciech-main" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
        }
    `);

    return (
        <ProjectMainLayout projectName={PROJECTS.ciech.name} isPageLast>
            <GridThree {...images} projectName={PROJECTS.ciech.name} />
        </ProjectMainLayout>
    );
};
