import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { GridWrapper } from 'src/components/GridWrapper';

const SLeft = styled.div`
    margin-right: 0.8rem;
    flex: 1;

    @media (max-width: 768px), (orientation: portrait) {
        margin-right: unset;
        margin-top: unset;
        margin-bottom: 0.8rem;
    }
`;

const SRight = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const SUpperImage = styled(GatsbyImage)`
    margin-bottom: 0.8rem;
    height: 43%;
`;

const SLowerImage = styled(GatsbyImage)`
    height: 55%;
    flex: 1;
`;

const SMainImage = styled(GatsbyImage)`
    height: 100%;
`;

export const GridThree = ({ upper, lower, main, projectName }) => {
    return (
        <GridWrapper>
            <SLeft>
                <SMainImage image={getImage(main)} alt={projectName} width="100%" height="100%" />
            </SLeft>
            <SRight>
                <SUpperImage image={getImage(upper)} alt={projectName} width="100%" height="100%" />
                <SLowerImage image={getImage(lower)} alt={projectName} height="100%" width="100%" />
            </SRight>
        </GridWrapper>
    );
};
